import { Prop, Vue, Component } from 'vue-property-decorator'
@Component
export default class ModalDelete extends Vue {
  @Prop() delete_modal_active!: boolean

  changeActiveDeleteModal() {
    this.$emit('closeModal', !this.delete_modal_active)
  }
  get show_modal() {
    return this.delete_modal_active ? 'is-active' : ''
  }
  remove() {
    this.$emit('remove')
    this.$buefy.toast.open({
      message: 'Eliminado exitosamente',
      type: 'is-success',
    })
  }
}
