import { Metric } from '@/Models'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)

@Component
export default class MciCard extends Vue {
  @Prop({ required: false })
  isBSC!: boolean
  @Prop({ required: true })
  metric!: Metric

  chart: Chart | null = null
  dayName = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes']
  monthData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  monthName = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ]
  monthNameinfo = {
    ENE: 'Enero',
    FEB: 'Febrero',
    MAR: 'Marzo',
    ABR: 'Abril',
    MAY: 'Mayo',
    JUN: 'Junio',
    JUL: 'Julio',
    AGO: 'Agosto',
    SEP: 'Septiembre',
    OCT: 'Octubre',
    NOV: 'Noviembre',
    DIC: 'Diciembre',
  }
  index_month: any = {
    ENE: 0,
    FEB: 1,
    MAR: 2,
    ABR: 3,
    MAY: 4,
    JUN: 5,
    JUL: 6,
    AGO: 7,
    SEP: 8,
    OCT: 9,
    NOV: 10,
    DIC: 11,
  }
  valids_keys: string[] = []
  metric_labels: any = []
  xValues: number[] = []
  upperLimit: number[] = []
  lowerLimmit: number[] = []
  dataSet: any[] = []
  // someVar = undefined
  // weekRange = this.someVar || ''
  mounted() {
    this.dataSet = new Array<any>(12).fill({
      value: '',
    })
    if (this.metric) {
      // console.log(this.metric.matrix_metric.metric_type, 'METRIC TYPE')
      switch (this.metric.matrix_metric.metric_type) {
        case 'diary':
          this.metric_labels = this.metric.metric_dataset.data_set.map(
            (x: any) =>
              x.dayName +
              new Date(x.date).toISOString().split('T')[0].substring(4),
          )
          break
        case 'weekly':
          this.metric.metric_dataset.data_set.forEach((x: any) => {
            if (x.weekRange)
              this.metric_labels.push(
                x.weekRange.substring(0, x.weekRange.indexOf('to') - 6) +
                  ' - ' +
                  x.weekRange.substring(
                    x.weekRange.indexOf('to') + 3,
                    x.weekRange.length - 5,
                  ),
              )
          })
          // x.weekRange.slice(0, x.weekRange.lastIndexOf('/')),
          break
        // case 'monthly':
        //   this.metric_labels = this.monthName
        //   break
        case 'monthly':
          this.valids_keys = this.metric.metric_dataset.data_set.map(
            (x: any) => x.month,
          )
          this.valids_keys = Object.keys(this.monthNameinfo).filter(
            (k: string) => this.valids_keys.includes(k),
          )

          this.metric_labels = this.valids_keys.map(
            (k: string) => (this.monthNameinfo as any)[k],
          )
          break
      }

      this.renderChart()
    }

    //console.log(this.metric.metric_dataset.data_set, 'FLUFLU')
  }

  get renderDataSet() {
    return
  }

  toShortFormat(date: Date) {
    const metric_labels_index = date.getMonth()
    const metric_labels = this.metric_labels[metric_labels_index]
    const year = date.getFullYear()
    return `${metric_labels} - ${year}`
  }

  renderChart() {
    if (this.metric.matrix_metric.metric_type == 'monthly') {
      this.metric.metric_dataset.data_set.forEach((x: any) => {
        switch (x.month) {
          case 'ENE':
            this.monthData[0] = x.value
            break
          case 'FEB':
            this.monthData[1] = x.value
            break
          case 'MAR':
            this.monthData[2] = x.value
            break
          case 'ABR':
            this.monthData[3] = x.value
            break
          case 'MAY':
            this.monthData[4] = x.value
            break
          case 'JUN':
            this.monthData[5] = x.value
            break
          case 'JUL':
            this.monthData[6] = x.value
            break
          case 'AGO':
            this.monthData[7] = x.value
            break
          case 'SEP':
            this.monthData[8] = x.value
            break
          case 'OCT':
            this.monthData[9] = x.value
            break
          case 'NOV':
            this.monthData[10] = x.value
            break
          case 'DIC':
            this.monthData[11] = x.value
            break
        }
      })
    }
    if (this.chart) this.chart.destroy()
    const ctx = (this.$refs.myChart as any).getContext('2d')

    //this.generateData('this.metric.upper_limit', 0, 10, 0.5)
    this.generateData('this.metric.upper_limit', 0, 10, 0.5)
    this.generateLowerData('this.metric.lower_limit', 0, 10, 0.5)
    // this.generateData('this.metric.lower_limit', 0, 10, 0.5)
    if (this.metric.matrix_metric.metric_type == 'monthly')
      this.monthData = this.monthData.filter((x: number) => x > 0)

    this.chart = new Chart(ctx, {
      type: 'line',
      // this.metric.matrix_metric.metric_type == 'monthly' ?  this.metric.metric_dataset.data_set.map((x: any) => x.month) :
      data: {
        labels: this.metric_labels,
        datasets: [
          {
            label: '',
            data:
              this.metric.matrix_metric.metric_type == 'monthly'
                ? this.monthData
                : this.metric.metric_dataset.data_set.map((x: any) => x.value),
            fill: true,
            borderColor: 'rgb(131, 47, 168)',
            tension: 0.1,
          },
          {
            data: this.upperLimit,
            borderColor: 'rgb(0, 128, 0, 100%)',
          },
          {
            data: this.lowerLimmit,
            borderColor: 'rgba(255,0,0, 50%)',
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    })
  }

  generateData(value: string, i1: number, i2: number, step = 1): void {
    for (let x = i1; x <= i2; x += step) {
      this.upperLimit.push(eval(value))
      this.xValues.push(x)
    }
  }
  generateLowerData(value: string, i1: number, i2: number, step = 1): void {
    for (let x = i1; x <= i2; x += step) {
      this.lowerLimmit.push(eval(value))
      this.xValues.push(x)
    }
  }

  deleteMetric() {
    this.$emit('deleteMetric', this.metric)
  }

  updateMetric() {
    this.$emit('updateMetric', this.metric)
  }
  addValue() {
    this.$emit('addValue', this.metric)
    //console.log(this.metric, 'MEEEEEEEEETRIC')
  }

  @Watch('metric')
  onPropertyChanged() {
    if (this.metric) this.renderChart()
  }
}
