import { Component, Vue, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import MciCard from '@/components/Cards/MciCard.vue'
import http from '@/services'
import { Metric, Matrix } from '@/Models'
import matrix from '@/store/matrix'
import AnimationBSC from '@/components/AnimationBSC/index.vue'
import menuModule from '@/store/menu'
import Datepicker from 'vue2-datepicker'
import ReportBSC from '@/components/ReporteBSC/ReporteBSC.vue'
import ExpandableButton from '@/components/ExpandableButton/index.vue'
import ModalComponent from '@/components/ModalComponent/ModalComponent.vue'
import { BalancedScoreCard } from '@/Models/BalancedScoreCard'
import { IItem } from '@/Models'
import { Perspectives } from '@/Models/Perspectives'
import ModalDelete from '@/components/ModalDelete/ModalDelete'

@Component({
  components: {
    MciCard,
    AnimationBSC,
    Datepicker,
    ReportBSC,
    ExpandableButton,
    ModalComponent,
    ModalDelete,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class BSC extends Vue {
  metric!: Metric
  data: Metric[] = []
  dataBalance: Array<BalancedScoreCard> = []
  balanceScoreCard: BalancedScoreCard = new BalancedScoreCard()
  balanceScoreCardReplace!: BalancedScoreCard
  perspectives: Perspectives = new Perspectives()
  dataMetricsBSC: Array<Metric> = []
  BSCYears: string[] = []
  metricBSC: Metric = new Metric()
  metricYearBSCSelected = ''
  typeSelected = ''
  modalAction = false
  action = 'list'
  formAction = false
  loading = false
  isLoading = false
  delete_modal_active = false
  isBSC!: boolean
  arrayBSC: string[] = []
  BSC: Array<IItem> = []
  matrix: Matrix | null = new Matrix()
  objKeys: any = [
    {
      items: [],
    },
  ]
  //objKeys = this.getperspectives()
  keySelected = ''
  filteredTags = this.objKeys
  code = 0
  tag = ''
  metricBSCSelected: Metric = new Metric()
  index = 0
  tagItem: any
  oldPerspectives: any = []

  async mounted() {
    //console.log(this.dataBalance, 'LLLKLLKLL')
    this.getDateYear()
    this.getperspectives()
    this.getMetricsBSC()

    this.matrix = matrix.matrix
    this.getMetricsPerspective()
    if (this.action == 'edit') {
      Object.assign(this.balanceScoreCard, this.balanceScoreCardReplace)
      //console.log(this.balanceScoreCardReplace, 'REPLACE')
    } else {
      this.balanceScoreCard = new BalancedScoreCard()
    }
  }
  // test() {
  //   console.log(this.metricBSCSelected, 'BSCSELECTED')
  // }
  get filteredKey() {
    // let datos: string[] = []
    return this.objKeys.filter((option: any) => {
      const titles = { items: option.items.map((x: any) => x.title) }
      return titles.toString().toLowerCase().indexOf(this.keySelected) >= 0
    })
    //   .forEach((j: any) => {
    //     datos = datos.concat(j.items)
    //   })
    // return datos.map((a: any) => a.title)
  }

  uuidv4() {
    return (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(
      /[018]/g,
      (c: any) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16),
    )
  }
  getFilteredTags() {
    return this.objKeys.filter((option: any) => {
      const titles = { items: option.items.map((x: any) => x.title) }
      return titles.toString().toLowerCase().indexOf(this.keySelected) >= 0
    })
  }

  showModal() {
    this.modalAction = true
    this.getBalanceScoreCardList()
  }
  closeForm() {
    this.modalAction = false
    this.formAction = false
    this.action = ''
    this.balanceScoreCard.title = ''
    this.balanceScoreCard.year = 0
  }
  // saveForm() {
  //   this.formAction = false
  // }

  //balance-score/perspective/
  async getperspectives() {
    try {
      const res = await http.get('balance-score/perspective/')
      if (!res.data) throw new Error(res.data)
      // console.log(res.data, 'BSC')
      this.perspectives = res.data
      //console.log(this.perspectives, 'NENENEN')
      res.data.forEach((x: any) => {
        this.objKeys[0].items.push({ id: x.id, title: x.title })
      })
      // console.log(this.objKeys, 'HOLIOOOOOOOOO')
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
  }

  async getBalanceScoreCardList() {
    try {
      this.loading = true
      const res = await http.get(
        `balance-score/balance-score-card/?year=${this.metricYearBSCSelected}`,
      )
      if (!res.data) throw new Error(res.data)
      this.dataBalance = res.data.map((x: BalancedScoreCard) => ({
        ...x,
      }))
      // console.log(this.dataBalance, 'DATABALANCE')
      this.isLoading = false
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
  }

  confirmation() {
    this.isLoading = true
    this.formAction = false
    this.balanceScoreCard.year = new Date().getFullYear()
    //console.log(this.balanceScoreCard, 'EEEEEE')
    if (this.action == 'edit') {
      this.updateBalanceScoreCardList()
      this.balanceScoreCard.title = ''
      this.balanceScoreCard.year = 0
      this.getperspectives()
    } else {
      this.createBalanceScoreCardList(this.balanceScoreCard)
    }
  }
  async createBalanceScoreCardList(balanceScoreCard: BalancedScoreCard) {
    // hero.hasOwnProperty('name')
    if (this.perspectives.title) {
      balanceScoreCard.perspectives = (this.perspectives.title as any).map(
        (x: any) => ({
          title: x.title != undefined ? x.title : x,
        }),
      )
    }
    try {
      await http.post('balance-score/balance-score-card/', balanceScoreCard)
      this.$buefy.toast.open({
        message: 'Agregado con exito',
        type: 'is-success',
      })
      this.isLoading = false
      this.getBalanceScoreCardList()
      this.balanceScoreCard.title = ''
      this.perspectives.title = ''
    } catch (error) {
      console.log(error)
      this.isLoading = false
      this.$buefy.toast.open({
        message: 'Ha ocurrido un error, intente nuevamente',
        type: 'is-danger',
      })
    }
  }
  removePerspective(value: any) {
    const index = this.oldPerspectives.findIndex((x: any) => x.title === value)
    if (index > -1) {
      this.perspectives.remove_perspectives.push(this.oldPerspectives[index].id)
    }
    this.objKeys[0].items.push(value)
  }
  addPerspective(value: any) {
    const index = this.objKeys[0].items.indexOf(value)
    this.objKeys[0].items.splice(index, 1)

    const indexPerspective = this.oldPerspectives.findIndex(
      (x: any) => x.title === value,
    )
    if (!this.perspectives.remove_perspectives) {
      this.perspectives['remove_perspectives'] = []
    }
    if (
      (this.perspectives.id as any) &&
      this.perspectives.remove_perspectives.includes(
        (this.perspectives.id as any)[indexPerspective],
      )
    ) {
      const indexRemove = this.perspectives.remove_perspectives.indexOf(
        this.oldPerspectives[indexPerspective].id,
      )
      if (indexRemove > -1) {
        this.perspectives.remove_perspectives.splice(indexRemove, 1)
      }
    }
  }
  async deleteBalanceScoreCardList(bscList: BalancedScoreCard) {
    this.balanceScoreCard = bscList
    this.balanceScoreCard.title
    try {
      await http.delete(
        `balance-score/balance-score-card/${this.balanceScoreCard}/`,
      )
      this.dataBalance = this.dataBalance.filter(
        (a: BalancedScoreCard) => a.id !== this.balanceScoreCard.id,
      )
      this.$buefy.toast.open({
        message: 'Eliminado con exito',
        type: 'is-success',
      })
      this.getBalanceScoreCardList()
    } catch (error) {
      console.log(error)
      this.$buefy.toast.open({
        message: 'Ha ocurrido un error, intente nuevamente',
        type: 'is-danger',
      })
    }
  }
  edit(bscList: BalancedScoreCard) {
    //this.perspectives = new Perspectives()
    this.oldPerspectives = []
    this.objKeys = [
      {
        items: [
          'Finanzas',
          'Educacion y Crecimiento',
          'Relacion Cliente',
          'Proceso interno',
        ],
      },
    ]
    this.perspectives.title = bscList.perspectives.map(
      (x: any) => x.title,
    ) as any
    this.perspectives.id = bscList.perspectives.map((y: any) => y.id) as any
    bscList.perspectives.forEach((x: any) => {
      this.addPerspective(x.title)
      this.oldPerspectives.push({
        id: x.id,
        title: x.title,
      })
    })
    this.formAction = true
    this.balanceScoreCard = bscList
    this.action = 'edit'
  }
  async updateBalanceScoreCardList() {
    const newBalance = {
      title: this.balanceScoreCard.title,
      id: this.balanceScoreCard.id,
      perspectives: (this.perspectives.title as any).map((y: any) => ({
        title: y,
      })),
      year: this.balanceScoreCard.year,
      remove_perspectives: this.perspectives.remove_perspectives,
    }
    if (newBalance.remove_perspectives.length == 0) {
      delete (newBalance as any).remove_perspectives
    }
    try {
      await http.put(
        `balance-score/balance-score-card/${this.balanceScoreCard.id}/`,
        newBalance,
      )
      this.$buefy.toast.open({
        message: 'Actualizado',
        type: 'is-success',
      })
      this.isLoading = false
      this.getBalanceScoreCardList()
    } catch (error) {
      console.log(error)
      this.isLoading = false
      this.$buefy.toast.open({
        message: 'Ha ocurrido un error, intente nuevamente',
        type: 'is-danger',
      })
    }
  }
  closeModal(valor: boolean) {
    this.delete_modal_active = valor
  }
  async getMetricsBSC() {
    this.loading = true

    try {
      const url = this.concatUrl(this.arrayBSC, `metric/get_metrics_bsc/`)
      const res = await http.get(url)
      if (!res.data) throw new Error(res.data)
      this.dataMetricsBSC = res.data.map((x: Metric) => ({
        ...x,
        id: x.id,
      }))
      // console.log(this.dataMetricsBSC, 'LEROLERO')
      // console.log(this.dataMetricsBSC)
      this.loading = false
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
  }

  async getDateYear() {
    this.loading = true

    try {
      const res = await http.get(`balance-score/get_years_bsc/`)
      if (!res.data) throw new Error(res.data)
      this.BSCYears = res.data
      //console.log(this.BSCYears, 'LERODATELERO')
      this.loading = false
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
  }

  async getDatesMetricsBSC() {
    this.loading = true

    try {
      const url = this.concatUrl(this.arrayBSC, `metric/get_metrics_bsc/`)
      const res = await http.get(url)
      if (!res.data) throw new Error(res.data)
      this.dataMetricsBSC = res.data.map((x: Metric) => ({
        ...x,
      }))
      //console.log(this.dataMetricsBSC, 'LEROLERO')
      this.loading = false
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
  }
  async setBSC() {
    let newFilter = []
    if (this.balanceScoreCard !== null) {
      newFilter = [`balance_scorecard__id=${this.balanceScoreCard.id}`]
    } else {
      newFilter = [`balance_scorecard__id=`]
    }
    this.arrayBSC = this.setparamsUrl(newFilter, this.arrayBSC)
    this.getMetricsBSC()
  }

  async setDate() {
    const newFilter = [`balance_scorecard__year=${this.metricYearBSCSelected}`]
    this.arrayBSC = this.setparamsUrl(newFilter, this.arrayBSC)
    await this.getBalanceScoreCardList()
    this.getMetricsBSC()
  }

  setPerspective() {
    console.log(this.typeSelected, 'LELOLELOLELO')
    const newFilter = [`perspective__id=${this.typeSelected}`]
    this.arrayBSC = this.setparamsUrl(newFilter, this.arrayBSC)
    this.getMetricsBSC()
  }

  setparamsUrl = (props: string[], filter: string[]) => {
    const newFilters = [...filter]
    props.forEach((prop) => {
      const index = newFilters.findIndex(
        (element) =>
          element.substring(0, element.indexOf('=')) ==
          prop.substring(0, element.indexOf('=')),
      )
      if (index > -1) {
        newFilters[index] = prop
      } else {
        newFilters.push(prop)
      }
    })
    return newFilters
  }

  concatUrl = (filters: string[], url: string): string => {
    if (filters.length > 0) {
      url += '?'
      filters.forEach((e: string, index: number) => {
        if (index + 1 === filters.length) {
          return (url += e)
        } else {
          return (url += e + '&')
        }
      })
    }
    return url
  }

  async getMetricsPerspective() {
    menuModule.setLoding(true)
    try {
      const res = await http.post(`metric/metric/get_metrics/`, {
        filter: this.typeSelected,
      })
      this.data = this.convertData(res.data).filter(
        (x: any) =>
          x.balance_score_type != null && x.in_balcance_score_card != false,
      )
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
    menuModule.setLoding(false)
  }
  convertData(data: []): Metric[] {
    return data.map((x: Metric) => ({
      ...x,
      date: new Date(x.matrix_metric.date),
      indicator_id: x.indicator?.id,
      employed_id: x.employed?.id,
      matrix_metric_id: x.matrix_metric.id,
      matrix_metric: {
        ...x.matrix_metric,
        activity_id: x.matrix_metric.activity.id,
        matrix_id: x.matrix_metric.matrix.id,
      },
    }))
  }
}
