import { Component, Vue } from 'vue-property-decorator'
import MciCard from '@/components/Cards/MciCard.vue'
import http from '@/services'
import { Metric, Matrix } from '@/Models'
import matrix from '@/store/matrix'
import AnimationBSC from '@/components/AnimationBSC/index.vue'
import ReporteBSC from '@/components/ReporteBSC/ReporteBSC.vue'
import * as html2pdf from 'html2pdf.js'
import menuModule from '@/store/menu'

@Component({
  components: {
    MciCard,
    AnimationBSC,
    ReporteBSC,
  },
})
export default class BSR extends Vue {
  data: Metric[] = []
  typeSelected = ''
  matrix: Matrix | null = new Matrix()
  async mounted() {
    this.matrix = matrix.matrix
    await this.getMetricsPerspective()
  }
  uuidv4() {
    return (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(
      /[018]/g,
      (c: any) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16),
    )
  }
  async to_image() {
    const el = this.$refs.printMe
    //const el = document.getElementsByClassName("tableColumn")[0];
    if (el) {
      const opt = {
        margin: [0.1, 0.1, 2, 0.1],
        filename: 'myfile.pdf',
        image: { type: 'jpeg', quality: 1 },
        html2canvas: { scale: 3 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'l' },
      }
      html2pdf().set(opt).from(el).save()
    }
  }

  async getMetricsPerspective() {
    menuModule.setLoding(true)
    try {
      const res = await http.post(`metric/metric/get_metrics/`, {
        filter: this.typeSelected,
      })
      this.data = this.convertData(res.data).filter(
        (x: any) =>
          x.balance_score_type != null && x.in_balcance_score_card != false,
      )
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
    menuModule.setLoding(false)
  }
  convertData(data: []): Metric[] {
    return data.map((x: Metric) => ({
      ...x,
      date: new Date(x.matrix_metric.date),
      indicator_id: x.indicator?.id,
      employed_id: x.employed?.id,
      matrix_metric_id: x.matrix_metric.id,
      matrix_metric: {
        ...x.matrix_metric,
        activity_id: x.matrix_metric.activity.id,
        matrix_id: x.matrix_metric.matrix.id,
      },
    }))
  }
}
