import { Component, Vue, Prop } from 'vue-property-decorator'
import { Breadcrumb, Collection } from '@/Models'
import BreadCrumbModule from '@/store/moduleBreadCrumb'
import BreadCrumb from '../BreadCrumb/BreadCrumb'
@Component
export default class CardCollection extends Vue {
  @Prop({ required: true })
  collection!: Collection
  collection_temp = new Collection()
  delete_modal_active = false
  edit_modal_active = false

  rgbToHex(r: number, g: number, b: number) {
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
  }

  editCollection() {
    const color = (this.$refs as any)[`color${this.collection.id}`] as any
    const color_selected = color.colorSelected
    this.collection.card_color = this.rgbToHex(
      Number(color_selected.red),
      Number(color_selected.green),
      Number(color_selected.blue),
    )
    if (
      !this.collection.card_icon ||
      typeof this.collection.card_icon === 'string'
    )
      this.collection.card_icon = null
    Object.assign(this.collection_temp, this.collection)
    this.$emit('updateCollection', this.collection)
    this.changeActiveEditModal()
  }

  cancelEdit() {
    Object.assign(this.collection, this.collection_temp)
    this.changeActiveEditModal()
  }

  get cssVars() {
    return {
      '--bg-color': this.collection.card_color,
    }
  }

  changeActiveDeleteModal() {
    this.delete_modal_active = !this.delete_modal_active
  }

  changeActiveEditModal() {
    Object.assign(this.collection_temp, this.collection)
    this.edit_modal_active = !this.edit_modal_active
  }

  get show_modal(): string {
    return this.delete_modal_active ? 'is-active' : ''
  }

  get edit_show_modal(): string {
    return this.edit_modal_active ? 'is-active' : ''
  }

  showMatrix() {
    const breadcrumb: Breadcrumb = {
      text: this.collection.name,
      icon: '',
      link: this.$route.path,
    }
    BreadCrumbModule.validateSetRoute(breadcrumb)
    console.log(this.$route)
    this.$router
      .push({
        path: '/matrices',
        query: { collection_id: this.collection.id + '' },
      })
      .catch(() => console.log)
  }

  async deleteCollection() {
    this.$emit('deleteCollection', this.collection)
  }

  showMyImage() {
    const imga: any = document.getElementById(`file_image${this.collection.id}`)
    const files = imga.files
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const imageType = /image.*/
      if (!file.type.match(imageType)) {
        continue
      }

      const img: any = document.getElementById(`thumbnail${this.collection.id}`)
      if (img) {
        img.file = file
        const reader = new FileReader()
        reader.onload = (function (aImg) {
          return function (e: any) {
            aImg.src = e.target.result
          }
        })(img)
        reader.readAsDataURL(file)
        this.collection.card_icon = file
      }
    }
  }
}
